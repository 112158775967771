var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mx-auto" }, [
    _vm.isLoadingProduct
      ? _c("div", { staticClass: "text-center text-gray text-sm" }, [
          _vm._v("\n        Loading product...\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.isLoadingProduct && _vm.product
      ? _c("div", [
          _c("div", { staticClass: "md:flex items-start pb-6" }, [
            _c("div", { staticClass: "w-full md:w-1/2 py-6 md:pr-3" }, [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-col w-16 flex-shrink-0 pr-2" },
                  _vm._l(_vm.product.images.edges, function (edge, index) {
                    return _c(
                      "div",
                      { key: edge.node.id, staticClass: "pb-2" },
                      [
                        _c(
                          "button",
                          {
                            class: {
                              "bg-transparent border-2 border-light-gray-3": 1,
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectedProductImageId = edge.node.id
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "w-16 h-16 object-cover",
                              attrs: {
                                src: edge.node.src,
                                alt: edge.node.altText + " shop image",
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "w-full pb-2" }, [
                  _c(
                    "div",
                    {
                      staticClass: "relative border border-light-gray-3",
                      staticStyle: { "padding-bottom": "75%" },
                    },
                    [
                      _c("img", {
                        staticClass:
                          "absolute top-0 left-0 w-full h-full object-contain",
                        attrs: {
                          src: _vm.selectedImage.src,
                          alt: _vm.selectedImage.altText + " shop image",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-full md:w-1/2 py-6 md:pl-3" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex justify-between pb-4 uppercase text-black tracking-wider",
                },
                [
                  _c("div", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.product.title) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                        £" +
                        _vm._s(_vm.price) +
                        "\n                    "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.product.descriptionHtml) },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "py-4" }, [
                _c("div", { staticClass: "inline-flex" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.amount,
                        expression: "amount",
                      },
                    ],
                    staticClass:
                      "w-12 mr-2 bg-white px-2 py-y bg-white border border-gray text-center",
                    attrs: {
                      type: "number",
                      min: "1",
                      step: "1",
                      disabled: _vm.isProductUnavailable,
                    },
                    domProps: { value: _vm.amount },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.amount = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      class: {
                        "px-8 py-3 text-white text-xs tracking-widest uppercase": 1,
                        "bg-green": !_vm.isAddingToCheckout,
                        "bg-gray-dark": _vm.isAddingToCheckout,
                      },
                      attrs: { disabled: _vm.isProductUnavailable },
                      on: { click: _vm.addToCheckout },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.isAddingToCheckout ? "Adding..." : "Add to Bag"
                          ) +
                          "\n                        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.checkout &&
                _vm.checkout.lineItems &&
                _vm.checkout.lineItems.length
                  ? _c("div", { staticClass: "py-2" }, [
                      _c(
                        "a",
                        {
                          staticClass:
                            "text-black text-xs hover:text-green focus:text-green underline cursor-pointer",
                          on: {
                            click: function ($event) {
                              return _vm.openBasketMenu()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Open basket & checkout...\n                        "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isProductUnavailable
                  ? _c("div", { staticClass: "py-2 text-xs text-red" }, [
                      _vm._v(
                        "\n                        This product is not available, please check back soon.\n                    "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "hidden lg:block bg-light-gray" },
            _vm._l(_vm.availableDetails, function (detail, index) {
              return _c(
                "button",
                {
                  key: "button-" + detail.key,
                  class: {
                    "uppercase px-3 py-2 hover:text-white text-sm mr-2": 1,
                    "bg-black text-white": _vm.selectedDetailKey === detail,
                    "hover:bg-green text-gray-dark":
                      _vm.selectedDetailKey !== detail,
                  },
                  on: {
                    click: function ($event) {
                      _vm.selectedDetailKey = detail.key
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(detail.key) + "\n            "
                  ),
                ]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "lg:p-4" },
      _vm._l(_vm.availableDetails, function (detail, index) {
        return _c(
          "div",
          {
            key: detail.key,
            class: {
              "pb-6": 1,
              "lg:hidden": _vm.selectedDetailKey
                ? _vm.selectedDetailKey !== detail.key
                : index !== 0,
            },
          },
          [
            _c("h3", { staticClass: "capitalize tracking-wider lg:hidden" }, [
              _vm._v(_vm._s(detail.key)),
            ]),
            _vm._v(" "),
            _c("p", {
              staticClass: "max-w-2xl",
              domProps: { textContent: _vm._s(detail.value) },
            }),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }