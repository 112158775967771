export class PinType
{
    /**
     * @param uuid {string}
     * @param title {string}
     * @param image_src {string|null} href / src
     * @param color {string|null} hex / rgb / rgba / hsl - any CSS supported color
     */
    constructor(uuid, title, image_src = null, color = null)
    {
        this.uuid = uuid;
        this.title = title;
        this.image_src = image_src;
        this.color = color;
    }

    /**
     *
     * @param title
     * @return {PinType}
     */
    setTitle(title)
    {
        this.title = title;
        return this;
    }

    /**
     *
     * @param image_src
     * @return {PinType}
     */
    setImageSrc(image_src)
    {
        this.image_src = image_src;
        return this;
    }

    /**
     *
     * @param color
     * @return {PinType}
     */
    setColor(color)
    {
        this.color = color;
        return this;
    }
}
