/**
 * Convert a string to camelCase
 * @param str non-camelcase string
 * @return {string} camelCase string
 */
export const camalize = (str) => {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

/**
 * Generate random hex colour
 * @return {string}
 */
export const randomHexColor = () => "#" + Math.floor(Math.random() * 16777215).toString(16);
