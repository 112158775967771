import Axios from "axios";

import Blueprint from "$blueprint/js/blueprint";
import Forms from "$blueprint/js/forms/index";
import Swiper from "$blueprint/js/swiper/index";

import ShopItem from "./shop/shop-item/index";
import BasketMenu from "./shop/basket-menu/index";
import Basket from "./shop/basket/index";

import Map from "./map/index";

window.axios = Axios;

Blueprint({
    SwupScrollPlugin: {
        offset: document.querySelector("#page header").getBoundingClientRect().height + 16,
    },
}).then(blueprint => {
    
    // show swiper on CMS and website. Only setup SWUP on website.
    Swiper.create().then(() => {
        if (blueprint.isWebsite){
            Swiper.swup(blueprint.swup);
        }
    });

    if (blueprint.isWebsite){
        Forms.create().then(Forms.swup(blueprint.swup));

        ShopItem.create().then(ShopItem.swup(blueprint.swup));
        Basket.create().then(Basket.swup(blueprint.swup));
        BasketMenu.create().then(BasketMenu.swup(blueprint.swup));
        Map.create().then(Map.swup(blueprint.swup));
    }
});
