import Vue from "$vue";
import ShopItem from "./ShopItem.vue";

export default {
    instances: [],

    destroy()
    {
        this.instances.forEach(instance => {
            instance.$destroy();
        });

        this.instances = [];
    },

    create()
    {
        return new Promise(resolve => {

            resolve();

            if (window.location.pathname.match(/^\/shop\/item\/.+/gi)){

                const rootElement = document.querySelector("#vue--shop-item");
                const productId = rootElement.getAttribute("data-product-id");

                const vue = new Vue({
                    components: {
                        ShopItem,
                    },
                    el: rootElement,
                    template: '<ShopItem/>',
                    data: {
                        productId,
                    },
                });

                this.instances.push(vue);

                console.log(this.instances);
            }
        });
    },

    /**
     * swup()
     * Detect swup events and correctly restart animation detection
     * @param  {Object} swup
     * @return {Promise}
     */
    swup(swup){
        return new Promise((resolve, reject) => {
            try {
                swup.on("willReplaceContent", event => {
                    this.destroy();
                });
                swup.on("contentReplaced", event => {
                    this.create();
                });
                resolve();
            } catch (error){
                console.error(error);
                reject(error);
            }
        });
    },
}
