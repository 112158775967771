var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", [
    _c("ul", { staticClass: "flex text-sm" }, [
      _c("li", { staticClass: "inline-block px-3" }, [
        _vm.checkout && _vm.hasItems
          ? _c(
              "button",
              {
                staticClass: "inline-block relative px-3 py-2 text-gray",
                on: { click: _vm.openBasketMenu },
              },
              [
                _vm.hasItems
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "absolute top-0 right-0 w-4 h-4 flex justify-center items-center rounded-full text-white bg-green transform translate-y-1",
                        staticStyle: { "font-size": "0.5rem" },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.checkout.lineItems.reduce(function (
                                carry,
                                item
                              ) {
                                return carry + item.quantity
                              },
                              0)
                            ) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("img", {
                  staticClass: "w-4 h-4",
                  attrs: { src: "/media/images/bag.png", alt: "Basket" },
                }),
              ]
            )
          : _c(
              "a",
              {
                staticClass: "inline-block relative px-3 py-2 text-gray",
                attrs: { href: "/shop" },
              },
              [
                _c("img", {
                  staticClass: "w-4 h-4",
                  attrs: { src: "/media/images/bag.png", alt: "Basket" },
                }),
              ]
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }