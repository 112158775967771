export class Pin
{
    /**
     * @param uuid {string}
     * @param title {string}
     * @param type {string|null} uuid of PinType
     * @param description {string|null}
     * @param lonLat {number[]|null}
     */
    constructor(uuid, title, type = null, description = null, lonLat = [null, null])
    {
        this.uuid = uuid;
        this.title = title;
        this.type = type;
        this.description = description;
        this.lonLat = lonLat;
    }

    /**
     *
     * @param title
     * @return {Pin}
     */
    setTitle(title)
    {
        this.title = title;
        return this;
    }

    /**
     *
     * @param type
     * @return {Pin}
     */
    setType(type)
    {
        this.type = type;
        return this;
    }

    /**
     *
     * @param description
     * @return {Pin}
     */
    setDescription(description)
    {
        this.description = description;
        return this;
    }

    /**
     *
     * @param lonLat
     * @return {Pin}
     */
    setLonLat(lonLat)
    {
        this.lonLat = lonLat;
        return this;
    }
}
