<template>
    <div class="container mx-auto">

        <div v-if="isLoadingProduct" class="text-center text-gray text-sm">
            Loading product...
        </div>

        <div v-if="!isLoadingProduct && product">

            <div class="md:flex items-start pb-6">
                <div class="w-full md:w-1/2 py-6 md:pr-3">
                    <div class="flex">
                        <div class="flex flex-col w-16 flex-shrink-0 pr-2">
                            <div
                                v-for="(edge, index) in product.images.edges"
                                :key="edge.node.id"
                                class="pb-2"
                            >
                                <button
                                    :class="{'bg-transparent border-2 border-light-gray-3':1,}"
                                    @click="selectedProductImageId = edge.node.id"
                                >
                                    <img
                                        :src="edge.node.src"
                                        :alt="edge.node.altText + ' shop image'"
                                        class="w-16 h-16 object-cover"
                                    >
                                </button>
                            </div>
                        </div>
                        <div class="w-full pb-2">
                            <div class="relative border border-light-gray-3" style="padding-bottom: 75%;">
                                <img
                                    class="absolute top-0 left-0 w-full h-full object-contain"
                                    :src="selectedImage.src"
                                    :alt="selectedImage.altText + ' shop image'"
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-full md:w-1/2 py-6 md:pl-3">
                    <div class="flex justify-between pb-4 uppercase text-black tracking-wider">
                        <div>
                            {{ product.title }}
                        </div>
                        <div>
                            £{{ price }}
                        </div>
                    </div>

                    <div v-html="product.descriptionHtml"></div>

                    <div class="py-4">
                        <div class="inline-flex">
                            <input
                                v-model="amount"
                                type="number"
                                min="1"
                                step="1"
                                class="w-12 mr-2 bg-white px-2 py-y bg-white border border-gray text-center"
                                :disabled="isProductUnavailable"
                            >
                            <button
                                :class="{'px-8 py-3 text-white text-xs tracking-widest uppercase':1, 'bg-green': !isAddingToCheckout, 'bg-gray-dark': isAddingToCheckout}"
                                @click="addToCheckout"
                                :disabled="isProductUnavailable"
                            >
                                {{ isAddingToCheckout ? 'Adding...':'Add to Bag' }}
                            </button>
                        </div>
                        <div v-if="checkout && checkout.lineItems && checkout.lineItems.length" class="py-2">
                            <a @click="openBasketMenu()" class="text-black text-xs hover:text-green focus:text-green underline cursor-pointer">
                                Open basket &amp; checkout...
                            </a>
                        </div>
                        <div class="py-2 text-xs text-red" v-if="isProductUnavailable">
                            This product is not available, please check back soon.
                        </div>
                    </div>
                </div>
            </div>

            <div class="hidden lg:block bg-light-gray">
                <button
                    v-for="(detail, index) in availableDetails"
                    :key="'button-' + detail.key"
                    :class="{'uppercase px-3 py-2 hover:text-white text-sm mr-2': 1, 'bg-black text-white' : selectedDetailKey === detail, 'hover:bg-green text-gray-dark': selectedDetailKey !== detail}"
                    @click="selectedDetailKey = detail.key"
                >
                    {{ detail.key }}
                </button>
            </div>
        </div>

        <div class="lg:p-4">
            <div
                v-for="(detail, index) in availableDetails"
                :key="detail.key"
                :class="{'pb-6':1, 'lg:hidden': selectedDetailKey ? selectedDetailKey !== detail.key : index !== 0}"
            >
                <h3 class="capitalize tracking-wider lg:hidden">{{ detail.key }}</h3>
                <p class="max-w-2xl" v-text="detail.value"></p>
            </div>
        </div>
    </div>
</template>

<script>
    import Shopify from "../shopify";

    export default {
        name: "ShopItem",

        data()
        {
            return {
                product: null,
                amount: 1,
                selectedProductImageId: null,
                selectedDetailKey: null,
                isLoadingProduct: false,
                isAddingToCheckout: false,
            };
        },

        computed: {
            checkout()
            {
                return window.shopifyCheckout ? window.shopifyCheckout.checkout : null;
            },
            selectedImage()
            {
                if (this.selectedProductImageId === null){
                    return this.product.images.edges[0].node;
                }

                return this.product.images.edges.find(edge => edge.node.id === this.selectedProductImageId).node;
            },
            price()
            {
                return this.product && this.product.variants.edges[0].node.price.amount ? Number.parseFloat(this.product.variants.edges[0].node.price.amount).toFixed(2) : 'N/A';
            },
            availableDetails()
            {
                return this.product && this.product.metafields ? Object.values(this.product.metafields).reduce((carry, item) => {
                    if (item) {
                        carry.push(item)
                    }
                    return carry;
                }, []): [];

                return this.product && this.product.metafields ? this.product.metafields.edges.map(edge => edge.node.key).sort() : [];
            },
            isProductUnavailable()
            {
                return this.product && this.product.variants.edges[0].node.available === false;
            },
        },

        methods: {
            loadProduct()
            {
                if (this.isLoadingProduct){
                    return;
                }

                this.isLoadingProduct = true;

                Shopify.loadProduct(this.$root.productId).then(data => {
                    this.product = data.node;

                    if (this.availableDetails.length){
                        this.selectedDetailKey = this.availableDetails[0].key;
                    }

                }).finally(() => {
                    this.isLoadingProduct = false;
                });
            },
            addToCheckout()
            {
                if (this.isLoadingProduct || this.product === null || this.isAddingToCheckout){
                    return;
                }

                this.isAddingToCheckout = true;

                Shopify.addToCheckout([{
                    variantId: this.product.variants.edges[0].node.id,
                    quantity: Math.max(1, JSON.parse(JSON.stringify(Number.parseInt(this.amount)))), // use Math.max to make sure only positive quantity is sent (no negative numbers from user)
                }]).then(() => {
                    this.isAddingToCheckout = false;
                })
            },
            openBasketMenu()
            {
                window.dispatchEvent(new CustomEvent("open-basket-menu"));
            },
        },
        mounted()
        {
            this.loadProduct();
        },
    }
</script>
