export class Map
{
    /**
     * @param version {string} we store a version number, to track breaking changes of structure. This applies to child structures too.
     * @param centerLonLat {number[]} defaults to center of UK
     * @param zoom {number}
     * @param pin_types {PinType[]}
     * @param pins {Pin[]}
     */
    constructor(version = "1.0.0", centerLonLat = [-4.0100069, 51.8583878], zoom = 16, pin_types = [], pins = [])
    {
        this.version = version;
        this.centerLonLat = centerLonLat;
        this.zoom = zoom;
        this.pin_types = pin_types;
        this.pins = pins;
    }

    /**
     *
     * @param centerLonLat
     * @return {Map}
     */
    setCenterLonLat(centerLonLat)
    {
        this.centerLonLat = centerLonLat;
        return this;
    }

    /**
     *
     * @param zoom
     * @return {Map}
     */
    setZoom(zoom)
    {
        this.zoom = zoom;
        return this;
    }

    /**
     *
     * @param pin_types
     * @return {Map}
     */
    setPinTypes(pin_types)
    {
        this.pin_types = pin_types;
        return this;
    }

    /**
     *
     * @param pins
     * @return {Map}
     */
    setPins(pins)
    {
        this.pins = pins;
        return this;
    }
}
