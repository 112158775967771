import mapboxgl from "mapbox-gl";
import {createMapFromJSON} from "../../../../cms/js/website-components/cms-components/mapbox-gl-map-with-pins/structure/map-factory";

export default {
    instances: [],

    destroy()
    {
        return new Promise(resolve => {
            this.instances.forEach(instance => {
                instance.mapboxMap.remove();
            });

            this.instances = [];

            resolve();
        });
    },

    create()
    {
        return new Promise(resolve => {

            const components = document.querySelectorAll(".component.component-mapbox-gl-map-with-pins");

            [...components].forEach(componentRootElement => {

                // get component data

                const componentDataElement = componentRootElement.querySelector("[data-map]");

                /**
                 * Decode map data. Note: map data is double encoded due to component data nuances.
                 * @type {Map}
                 */
                const map = createMapFromJSON(JSON.parse(JSON.parse(componentDataElement.getAttribute("data-map"))));

                /**
                 * @type {String}
                 */
                const accessToken = JSON.parse(componentDataElement.getAttribute("data-mapbox-access-token"));

                /**
                 *
                 * @type {String}
                 */
                const styleUrl = JSON.parse(componentDataElement.getAttribute("data-mapbox-style-url"));

                // construct map on root element

                mapboxgl.accessToken = accessToken;

                const mapboxMap = new mapboxgl.Map({
                    container: componentDataElement,
                    style: styleUrl,
                    center: map.centerLonLat,
                    zoom: map.zoom,
                });

                // mapboxMap.scrollZoom.setWheelZoomRate(0.02); // Default 1/450

                // Bring Google's CTRL + Scroll feature to reality. Solution "stolen" from Github issue, then extended!
                // https://github.com/mapbox/mapbox-gl-js/issues/6884#issuecomment-603230145
                mapboxMap.on("wheel", event => {
                    if (event.originalEvent.ctrlKey) { // Check if CTRL key is pressed
                        event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
                        if (!mapboxMap.scrollZoom._enabled) mapboxMap.scrollZoom.enable(); // Enable zoom only if it's disabled
                        componentRootElement.classList.remove("show-scroll-warning");
                    } else {
                        if (mapboxMap.scrollZoom._enabled){
                            mapboxMap.scrollZoom.disable(); // Disable zoom only if it's enabled
                            componentRootElement.classList.add("show-scroll-warning");

                            setTimeout(() => {
                                componentRootElement.classList.remove("show-scroll-warning");
                                mapboxMap.scrollZoom.enable(); // we re-enable, so the wheel event can be re-triggered, therefore showing the warning again!
                            }, 3000);
                        }
                    }
                });

                // generate markers
                map.pins.forEach(pin => {

                    const markerElement = document.createElement("div");
                    markerElement.className = "mapbox-map-marker";

                    const pinType = pin.type ? map.pin_types.find(pinType => pinType.uuid === pin.type) : null;

                    if (pinType && pinType.image_src){
                        markerElement.setAttribute("data-type-uuid", pinType.uuid);
                        markerElement.style.backgroundImage = `url(${pinType.image_src})`;
                        markerElement.style.backgroundPosition = "center";
                        markerElement.style.backgroundSize = "cover";
                    }
                    else if (pinType){
                        markerElement.style.backgroundColor = pinType.color;
                    }
                    else {
                        markerElement.style.backgroundColor = "rgb(50, 50, 50)";
                    }

                    markerElement.style.width = "32px";
                    markerElement.style.height = "32px";

                    markerElement.style.borderRadius = "100%";

                    const markerTitleElement = document.createElement("div");
                    markerTitleElement.style.position = "absolute";
                    markerTitleElement.style.top = "100%";
                    markerTitleElement.style.left = "50%";
                    markerTitleElement.style.transform = "translateX(-50%) translateY(.5rem)";

                    if (pinType){
                        markerTitleElement.style.color = pinType.color;
                    }

                    markerTitleElement.innerText = pin.title;

                    markerElement.appendChild(markerTitleElement);

                    const marker = new mapboxgl.Marker(markerElement).setLngLat(pin.lonLat);

                    let popup = null;

                    // if there's a description, add popup. If pin currently being edited, show the popup text open in realtime.
                    if (pin.description){
                        popup = new mapboxgl.Popup({
                            offset: 25,
                            className: "cms-whitespace-pre-wrap",
                        }).setText(pin.description);

                        marker.setPopup(popup);
                    }

                    marker.addTo(mapboxMap);
                });

                setTimeout(() => {
                    window.dispatchEvent(new CustomEvent("mapbox-setup", {
                        detail: {
                            map: map,
                        },
                    }));
                }, 300);

                // save instance

                this.instances.push({
                    mapboxMap,
                })
            });

            resolve();
        });
    },

    /**
     * swup()
     * Detect swup events and correctly restart animation detection
     * @param  {Object} swup
     * @return {Promise}
     */
    swup(swup){
        return new Promise((resolve, reject) => {
            try {
                swup.on("willReplaceContent", event => {
                    this.destroy();
                });
                swup.on("contentReplaced", event => {
                    this.create();
                });
                resolve();
            } catch (error){
                console.error(error);
                reject(error);
            }
        });
    },
}
