import { v4 as uuidv4 } from 'uuid';
import {PinType} from "./pin-type";
import {randomHexColor} from "../../../../small-libs";

export const createPinTypeFromJSON = pinTypeJson => {

    if (!pinTypeJson) return new PinType(uuidv4(), "Untitled", null, randomHexColor());

    return new PinType(pinTypeJson.uuid, pinTypeJson.title, pinTypeJson.image_src, pinTypeJson.color);
};
