import {Map} from "./map";
import {createPinFromJSON} from "./pin-factory";
import {createPinTypeFromJSON} from "./pin-type-factory";

export const createMapFromJSON = json => {

    if (!json) return new Map();

    return new Map(json.version, json.centerLonLat, json.zoom, json.pin_types.map(createPinTypeFromJSON), json.pins.map(createPinFromJSON));
}
