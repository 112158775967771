import { render, staticRenderFns } from "./BasketMenu.vue?vue&type=template&id=74953e80&"
import script from "./BasketMenu.vue?vue&type=script&lang=js&"
export * from "./BasketMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\git\\nimble-cms-2020\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74953e80')) {
      api.createRecord('74953e80', component.options)
    } else {
      api.reload('74953e80', component.options)
    }
    module.hot.accept("./BasketMenu.vue?vue&type=template&id=74953e80&", function () {
      api.rerender('74953e80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/websites/odysseyinnovation_com/js/shop/basket-menu/BasketMenu.vue"
export default component.exports