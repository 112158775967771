import Vue from "$vue";
import Basket from "./Basket.vue";

export default {
    instances: [],

    destroy()
    {
        this.instances.forEach(instance => {
            instance.$destroy();
        });

        this.instances = [];
    },

    create()
    {
        return new Promise(resolve => {

            resolve();

            const rootElement = document.querySelector("#vue--basket");

            const vue = new Vue({
                components: {
                    Basket,
                },
                el: rootElement,
                template: '<Basket/>',
            });

            this.instances.push(vue);
        });
    },

    /**
     * swup()
     * Detect swup events and correctly restart animation detection
     * @param  {Object} swup
     * @return {Promise}
     */
    swup(swup){
        return new Promise((resolve, reject) => {
            try {
                swup.on("willReplaceContent", event => {
                    this.destroy();
                });
                swup.on("contentReplaced", event => {
                    this.create();
                });
                resolve();
            } catch (error){
                console.error(error);
                reject(error);
            }
        });
    },
}
