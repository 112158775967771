import Client from 'shopify-buy';
import Axios from "axios";
import Vue from "$vue";

const rootElement = document.querySelector("#shopify-details");

const storefrontAccessToken = rootElement.getAttribute("data-storefront-access-token");
const shopUrl = rootElement.getAttribute("data-shop-url");

const client = Client.buildClient({
    domain: shopUrl,
    storefrontAccessToken: storefrontAccessToken,
});

const SHOPIFY_CHECKOUT_STORAGE_KEY = "shopify-checkout-2023-08-23"

let localStorageCheckout = localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY) ? JSON.parse(localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)) : null;

// if localStorageCheckout.updatedAt older than 14 days, remove it. Shopify tends to expire within 30 days.
if (localStorageCheckout && localStorageCheckout.updatedAt && new Date(localStorageCheckout.updatedAt) < new Date(new Date().setDate(new Date().getDate() - 14))) {
    localStorageCheckout = null;
}

window.shopifyCheckout = Vue.observable({
    checkout: localStorageCheckout,
});

if (!localStorageCheckout){
    client.checkout.create().then(checkout => {
        window.shopifyCheckout.checkout = checkout;
    })
}

console.log({client});

const shopifyApi = {
    checkout: window.shopifyCheckout,

    addToCheckout(items = [])
    {
        return new Promise(resolve => {
            client.checkout.addLineItems(window.shopifyCheckout.checkout.id, items).then(checkout => {
                window.shopifyCheckout.checkout = checkout;
                localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, JSON.stringify(checkout));
                resolve();
            });
        });
    },
    removeFromCheckout(itemIds = [])
    {
        return new Promise(resolve => {
            client.checkout.removeLineItems(window.shopifyCheckout.checkout.id, itemIds).then(checkout => {
                window.shopifyCheckout.checkout = checkout;
                localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, JSON.stringify(checkout));
                resolve();
            });
        });
    },
    updateQuantity(itemId, quantity)
    {
        return new Promise(resolve => {
            client.checkout.updateLineItems(window.shopifyCheckout.checkout.id, [{
                id: itemId,
                quantity: quantity,
            }]).then(checkout => {
                window.shopifyCheckout.checkout = checkout;
                localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, JSON.stringify(checkout));
                resolve();
            });
        });
    },
    allProducts()
    {
        return new Promise((resolve, reject) => {
            client.product.fetchAll().then(resolve).catch(reject);
        });
    },
    loadProduct(id)
    {
        return new Promise((resolve, reject) => {

            // we need to run a lovely, custom GraphQL query - to be able to pull product meta fields (not supported by default).

            const productQuery = `
                fragment VariantFragment on ProductVariant {
                    id
                    title
                    price {
                        amount
                    }
                    weight
                    available: availableForSale
                    sku
                    image {
                        id
                        src: originalSrc
                        altText
                    }
                    selectedOptions {
                        name
                        value
                    }
                }

                fragment ProductFragment on Product {
                    id
                    createdAt
                    updatedAt
                    descriptionHtml
                    handle
                    productType
                    title
                    publishedAt
                    onlineStoreUrl
                    metafields(identifiers: [
                        {
                            namespace: "global",
                            key: "features", 
                        },
                        {
                            namespace: "global",
                            key: "delivery", 
                        },
                        {
                            namespace: "global",
                            key: "specs", 
                        },
                        {
                            namespace: "global",
                            key: "warrenty", 
                        },
                    ]) {
                        key
                        value
                    }
                    options {
                        id
                        name
                        values
                    }
                    images(first: 5) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                        }
                        edges {
                            cursor
                            node {
                                id
                                src
                                altText
                            }
                        }
                    }
                    variants(first: 20) {
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                        }
                        edges {
                            cursor
                            node {
                                ...VariantFragment
                            }
                        }
                    }
                }

                query ($id: ID!) {
                    node(id: $id) {
                        __typename
                        ...ProductFragment
                    }
                }
            `;

            Axios.post("https://" + shopUrl + "/api/2022-10/graphql", {
                query: productQuery,
                variables: {
                    id: `gid://shopify/Product/${id}`,
                },
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-Shopify-Storefront-Access-Token": storefrontAccessToken
                },
            }).then(response => {
                resolve(response.data.data);
            }).catch(reject);
        });
    },
};

export default shopifyApi;

window.shopifyApi = shopifyApi;
