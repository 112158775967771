import Vue from "$vue";
import BasketMenu from "./BasketMenu.vue";

export default {
    instances: [],

    destroy()
    {
        this.instances.forEach(instance => {
            instance.$destroy();
        });

        this.instances = [];
    },

    create()
    {
        return new Promise(resolve => {

            resolve();

            const rootElement = document.querySelector("#vue--basket-menu");

            const vue = new Vue({
                components: {
                    BasketMenu,
                },
                el: rootElement,
                template: '<BasketMenu/>',
                data: {
                    isOpen: false,
                },
                watch: {
                    isOpen: function(newValue){
                        if (newValue === true){
                            document.querySelector("html").style.overflow = "hidden";
                        }
                        else {
                            document.querySelector("html").style.overflow = null;
                        }
                    },
                },
                methods: {
                    open()
                    {
                        this.isOpen = true;
                    },
                },
                mounted()
                {
                    window.addEventListener("open-basket-menu", this.open);
                },
                beforeDestroy()
                {
                    window.removeEventListener("open-basket-menu", this.open);
                },
            });

            this.instances.push(vue);
        });
    },

    /**
     * swup()
     * Detect swup events and correctly restart animation detection
     * @param  {Object} swup
     * @return {Promise}
     */
    swup(swup){
        return new Promise((resolve, reject) => {
            try {
                swup.on("willReplaceContent", event => {
                    this.destroy();
                });
                swup.on("contentReplaced", event => {
                    this.create();
                });
                resolve();
            } catch (error){
                console.error(error);
                reject(error);
            }
        });
    },
}
