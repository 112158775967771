<template>
    <nav>
        <ul class="flex text-sm">
            <li class="inline-block px-3">
                <button
                    v-if="checkout && hasItems"
                    @click="openBasketMenu"
                    class="inline-block relative px-3 py-2 text-gray"
                >
                    <div
                        class="absolute top-0 right-0 w-4 h-4 flex justify-center items-center rounded-full text-white bg-green transform translate-y-1"
                        style="font-size: 0.5rem;"
                        v-if="hasItems"
                    >
                        {{ checkout.lineItems.reduce((carry, item) => carry + item.quantity, 0) }}
                    </div>
                    <img class="w-4 h-4" src="/media/images/bag.png" alt="Basket">
                </button>
                <a
                    v-else
                    href="/shop"
                    class="inline-block relative px-3 py-2 text-gray"
                >
                    <img class="w-4 h-4" src="/media/images/bag.png" alt="Basket">
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "Basket",

        computed: {
            checkout()
            {
                return window.shopifyCheckout ? window.shopifyCheckout.checkout : null;
            },
            hasItems()
            {
                return this.checkout && this.checkout.lineItems.length;
            },
        },

        methods: {
            openBasketMenu()
            {
                window.dispatchEvent(new CustomEvent("open-basket-menu"));
            },
        },
    }
</script>
