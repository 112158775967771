<template>
    <div v-if="$root.isOpen" class="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-white z-50">

        <button class="absolute top-0 right-0 transform -translate-x-4 translate-y-6 p-4 text-sm" @click="$root.isOpen = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="w-4 h-4"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
        </button>

        <div class="container mx-auto relative max-w-lg">
            <div class="overflow-auto w-full" style="height: 300px;">
                <div
                    v-for="(item, index) in checkout.lineItems"
                    class="border-b border-light-gray-2 py-3"
                >
                    <div class="flex">
                        <img
                            class="w-24 h-24 object-cover mx-2 mb-2 flex-shrink-0"
                            :src="item.variant.image && item.variant.image.src ? item.variant.image.src : '/placehold/200/200'"
                            :alt="item.variant.image && item.variant.image.altText ? item.variant.image.altText : 'Product Image Missing'"
                        >
                        <div class="w-full">
                            <div class="pb-2 text-black">
                                <strong>£{{ Number.parseFloat(item.variant.price.amount).toFixed(2) }}</strong> {{ item.title }}
                            </div>

                            <div class="inline-flex">
                                <button
                                    @click="updateQuantity(item, item.quantity - 1)"
                                    :disabled="isBusy"
                                    class="inline-flex justify-center items-center rounded-full w-6 h-6 bg-light-gray-2 text-black"
                                >
                                    -
                                </button>
                                <div class="flex-shrink-0 px-2">
                                    {{ item.quantity }}
                                </div>
                                <button
                                    @click="updateQuantity(item, item.quantity + 1)"
                                    :disabled="isBusy"
                                    class="inline-flex justify-center items-center rounded-full w-6 h-6 bg-light-gray-2 text-black"
                                >
                                    +
                                </button>
                            </div>
                            <div class="pt-2">
                                <button
                                    @click="removeItem(item)"
                                    :disabled="isBusy"
                                    class="text-red text-xs underline"
                                >
                                    Remove Item
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pt-8 pb-4 text-center text-green">
                <strong>Subtotal: £{{ Number.parseFloat(checkout.subtotalPrice.amount).toFixed(2) }}</strong>
            </div>

            <div>
                <a
                    v-if="checkout.webUrl"
                    :href="checkout.webUrl"
                    :disabled="isBusy"
                    :class="`block w-full py-4 px-4 ${isBusy ? 'bg-gray':'bg-green'} text-white text-center`"
                >
                    {{ isBusy ? 'Updating...':'Checkout' }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Basket",

        data()
        {
            return {
                isBusy: false,
            };
        },

        computed: {
            checkout()
            {
                return window.shopifyCheckout ? window.shopifyCheckout.checkout : null;
            },
            hasItems()
            {
                return this.checkout && this.checkout.lineItems.length;
            },
        },

        watch: {
            checkout: {
                deep: true,
                handler: function(newValue)
                {
                    if (newValue.lineItems.length === 0){
                        this.$root.isOpen = false;
                    }
                },
            },
        },

        methods: {
            updateQuantity(item, quantity)
            {
                if (this.isBusy){
                    return;
                }

                this.isBusy = true;

                window.shopifyApi.updateQuantity(item.id, quantity).then(() => {
                    this.isBusy = false;
                });
            },
            removeItem(item)
            {
                if (this.isBusy){
                    return;
                }

                this.isBusy = true;

                window.shopifyApi.removeFromCheckout([item.id]).then(() => {
                    this.isBusy = false;
                });
            },
        },
    }
</script>
